
import { Component, Vue } from 'vue-property-decorator'
import ImportData from '@/components/importTemplateData/Index.vue'
import { WorkerDetail, TeamDetail } from '@/types/worker'
import { Page } from '@/types/common'
import { ElForm } from 'element-ui/types/form'
import { KeepAlive } from '@/utils/decorators'

@Component({
  components: { ImportData }
})
@KeepAlive
export default class List extends Vue {
  private searchInfo: { projectName: string; workerName: string } = {
    projectName: '',
    workerName: ''
  }

  private itemList = [
    {
      label: '工人姓名',
      prop: 'workerName'
    },
    {
      label: '工人分组',
      prop: 'workerTeamName',
      show: 'template'
    },
    {
      label: '身份证号',
      prop: 'identityCard',
      show: 'template'
    },
    {
      label: '所属项目',
      prop: 'projectName'
    },
    {
      label: '电话号码',
      prop: 'mobile'
    },
    {
      label: '年龄',
      prop: 'age'
    },
    {
      label: '性别',
      prop: 'sex',
      show: 'template'
    },
    {
      label: '家庭住址',
      prop: 'homeAddress'
    },
    {
      label: '紧急联系人',
      prop: 'emergencyPerson'
    },
    {
      label: '联系号码',
      prop: 'emergencyPersonMobile'
    }
  ]

  private tableData: WorkerDetail[] = []
  private total = 0
  private page = 1
  private size = 10
  private loading = false
  private isShowImport = false
  // 班组添加
  private isShowTeam = false
  private isLoadTeam = false
  private addTeamLine: number | null = null
  private teamList: TeamDetail[] = []
  private addTeamForm: TeamDetail = this.initAddTeamForm

  private addTeamRules = {
    teamName: [
      { required: true, message: '请输入班组名称', whitespace: true }
    ],
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ]
  }

  private teamUserList: WorkerDetail[] = []

  get projectList () {
    return this.$store.state.projectList
  }

  get initAddTeamForm () {
    return {
      teamId: '',
      teamName: '',
      projectId: '',
      teamManagerId: ''
    }
  }

  $refs!: {
    addTeamForm: ElForm;
    addTeamTable: any;
  }

  created (): void {
    this.getList()
  }

  // 搜索
  onSearch (): void {
    this.page = 1
    this.getList()
  }

  // 获取列表数据
  getList (): void {
    this.loading = true
    this.$axios.get<Page<WorkerDetail>>(this.$apis.worker.selectProjectWorkerByPage,
      {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then(res => {
      this.total = res.total
      this.tableData = res.list
    }).finally(() => {
      this.loading = false
    })
  }

  // 获取班组列表
  getTeamList (): void {
    this.isLoadTeam = true
    this.$axios.get<Page<TeamDetail>>(this.$apis.worker.selectProjectUserTeamByList, {
      notBuildWorker: '1'
    }).then(res => {
      this.teamList = res.list
    }).finally(() => {
      this.isLoadTeam = false
    })
  }

  // 获取列表数据
  getTeamUserList (): void {
    this.$axios.get<Page<WorkerDetail>>(this.$apis.worker.selectProjectWorkerByList,
      {
        workerTeamId: this.addTeamForm.teamId
      }).then(res => {
      this.teamUserList = res.list || []
    })
  }

  // 新增工人
  addWorker (): void {
    this.$router.push({ name: 'projectWorkerAdd' })
  }

  // 新增班组
  addWorkerTeam (): void {
    this.isShowTeam = true
    this.getTeamList()
  }

  addTeamUpdate (index: number, row: TeamDetail) {
    if (this.addTeamLine === index) {
      this.$refs.addTeamForm.validate(valid => {
        if (valid) {
          const url = this.addTeamForm.teamId ? this.$apis.worker.updateProjectUserTeam : this.$apis.worker.insertProjectUserTeam
          this.$axios.post(url, this.addTeamForm).then(() => {
            this.$message.success(this.addTeamForm.teamId ? '编辑班组成功' : '添加班组成功')
            this.addTeamLine = null
            this.getTeamList()
            this.addTeamForm = JSON.parse(JSON.stringify(this.initAddTeamForm))
          })
        }
      })
    } else if (this.addTeamLine !== null) {
      // 首次未保存
      this.$message.error('请先保存')
    } else {
      this.addTeamLine = index
      this.addTeamForm = { ...row }
      this.getTeamUserList()
    }
  }

  addTeamFormNew () {
    if (!this.addTeamLine) {
      this.addTeamForm = JSON.parse(JSON.stringify(this.initAddTeamForm))
      this.teamList.push(this.addTeamForm)
      this.addTeamLine = this.teamList.length - 1
      // 滚动到表格最底部
      this.$nextTick(() => {
        this.$refs.addTeamTable.bodyWrapper.scrollTop = this.$refs.addTeamTable.bodyWrapper.scrollHeight
      })
      this.teamUserList = []
    } else {
      this.$message.error('请先保存')
    }
  }

  addTeamDelete (row: TeamDetail) {
    this.$confirm(`是否删除班组【${row.teamName}】?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.worker.deleteProjectUserTeam,
        {
          teamId: row.teamId
        }).then(() => {
        this.$message.success('删除成功')
        this.addTeamLine = null
        this.getTeamList()
      })
    })
  }

  closeAddTeam () {
    this.addTeamLine = null
    this.addTeamForm = JSON.parse(JSON.stringify(this.initAddTeamForm))
  }

  // 删除判断(删除前先判断是否绑定设备，是则先解绑)
  deleteConfirm (row: WorkerDetail): void {
    this.$axios.get(this.$apis.worker.countBoundDevice, {
      workerId: row.workerId
    }).then(res => {
      const params = {
        ajax: '',
        name: ''
      }
      switch (res) {
        case '1006':
          params.ajax = this.$apis.bracelet.unBindWatchWorker
          params.name = '手环'
          break
        case '1023':
          params.ajax = this.$apis.workcard.updateUnBindWorkCard
          params.name = '工牌'
          break
        default:
          break
      }
      if (params.name !== '') {
        this.$confirm(`是否解除${params.name}绑定，并删除工人【${row.workerName}】?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.post(params.ajax, {
            workerId: row.workerId
          }).then(() => {
            this.deleteWorker(row.workerId)
          })
        })
      } else {
        this.$confirm(`是否删除工人【${row.workerName}】?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.deleteWorker(row.workerId)
        })
      }
    })
  }

  // 删除工人
  deleteWorker (id: string | undefined) {
    this.$axios.post(this.$apis.worker.deleteProjectWorker,
      {
        workerId: id
      }).then(() => {
      this.$message.success('删除成功')
      this.onSearch()
    })
  }
}
